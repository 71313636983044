.panelTable{
    display: grid;
    background-color: rgba(181, 155, 255, 0.10);
    backdrop-filter: blur(2.5px);
    border-radius: 3.5vw;
    width: 100%;
    height: 0;
    padding-bottom: 75.825%;
    overflow: hidden;
}
.tableUserName{
    position: relative;
    width: 100%;
    height: 3.5vw;
    top: -2.2vw;
    font-size: 2vw;
    border-radius: 3.5vw 3.5vw 0px 0px;
    border-bottom: 0.2vw solid rgba(160, 77, 181, 0.71);
    background: #B59BFF;
    backdrop-filter: blur(2.5px);
}
.tableUserNameText{
    display: flex;
    width: 50%;
    height: 3.5vw;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.tableDate{
    position: relative;
    width: 50%;
    left: 50%;
    height: 3.5vw;
    top: -5.9vw;
    font-size: 2vw;
    border-radius: 3.5vw 3.5vw 0px 3.5vw;
    border-bottom: 0.2vw solid #5F3F93;
    background: #2A245F;
    backdrop-filter: blur(2.5px);
}
.tableDateText{
    display: flex;
    width: 100%;
    height: 3.5vw;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.tableChakrasContainer{
    position: relative;;
    margin-top: 2vw;
    top: -4vw;
}
.tableChakra {
    width: 100%;
    height: 2.8vw;
    border-radius: 0.8vw;
}
.table7Chakra {
    background: rgba(68, 39, 245, 0.15);
}
.table6Chakra {
    background: rgba(0, 10, 255, 0.15);
}
.table5Chakra {
    background: rgba(0, 102, 255, 0.15);
}
.table4Chakra {
    background: rgba(27, 181, 33, 0.15);
}
.table3Chakra {
    background: rgba(255, 245, 0, 0.15);
}
.table2Chakra {
    background: rgba(255, 122, 0, 0.15);
}
.table1Chakra {
    background: rgba(255, 0, 0, 0.15);
}

.table7ChakraColor {
    color: #5000D1;
}
.table6ChakraColor {
    color: #0057FF;
}
.table5ChakraColor {
    color: #0386FF;
}
.table4ChakraColor {
    color: #1BB521;
}
.table3ChakraColor {
    color: #FFF500;
}
.table2ChakraColor {
    color: #FF7A00;
}
.table1ChakraColor {
    color: #F00;
}

.matrixImageT{
    grid-area: 1 / 1;
    position: relative;
}
.matrixTextTstart {
    grid-area: 1 / 1;
    width: fit-content;
    text-align: left;
    position: relative;
    font-size: 1.55vw;
}
.BigNumberT{
    grid-area: 1 / 1;
    width: fit-content;
    text-align: center;
    position: relative;
    font-size: 2.5vw;
}
.MediumNumberT{
    grid-area: 1 / 1;
    width: fit-content;
    text-align: center;
    position: relative;
    font-size: 1.55vw;
}
.SmallNumberT{
    grid-area: 1 / 1;
    width: fit-content;
    text-align: center;
    position: relative;
    font-size: 1vw;
}
#idt0 {
    left: -43.7%;
    top: 45.6%;
}
#idt1 {
    left: 0%;
    top: 2%;
}
#idt2 {
    left: 43.7%;
    top: 45.6%;
}
#idt3 {
    left: 0%;
    top: 88.9%;
}
#idt4 {
    left: 0%;
    top: 45.6%;
}
#idt13 {
    left: -30.6%;
    top: 14.6%;
}
#idt19 {
    left: 30.6%;
    top: 14.6%;
}
#idt16 {
    left: 30.6%;
    top: 75.8%;
}
#idt22 {
    left: -30.6%;
    top: 75.8%;
}
#idt10 {
    left: -35.4%;
    top: 46.8%;
}
#idt15 {
    left: -24.9%;
    top: 21.8%;
}
#idt8 {
    left: 0;
    top: 11.3%;
}
#idt21 {
    left: 24.9%;
    top: 21.8%;
}
#idt12 {
    left: 35.4%;
    top: 46.8%;
}
#idt18 {
    left: 25.1%;
    top: 71.7%;
}
#idt6 {
    left: 0%;
    top: 82.1%;
}
#idt24 {
    left: -25.1%;
    top: 71.7%;
}
#idt9 {
    left: -30.6%;
    top: 48.2%;
}
#idt14 {
    left: -21.7%;
    top: 26.5%;
}
#idt7 {
    left: 0%;
    top: 17.5%;
}
#idt20 {
    left: 21.7%;
    top: 26.5%;
}
#idt11 {
    left: 30.6%;
    top: 48.2%;
}
#idt17 {
    left: 21.7%;
    top: 69.8%;
}
#idt5 {
    left: 0%;
    top: 78.8%;
}
#idt23 {
    left: -21.7%;
    top: 69.8%;
}
#idt26 {
    left: -15.3%;
    top: 48.2%;
}
#idt25 {
    left: 0%;
    top: 32.8%;
}
#idt28 {
    left: 8%;
    top: 70.9%;
}
#idt27 {
    left: 15.3%;
    top: 63.4%;
}
#idt29 {
    
    left: 22.35%;
    top: 56.5%;
}

.vectorColor {
    margin-left: 0.3vw;
    width: 1vw;
}
.vectorColor:hover {
    cursor: pointer;
}