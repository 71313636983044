.mainProfileContainer {
    margin-top: -82px;
    height: 135vh;
    background-image: url('./resources/6.png');
    background-repeat: repeat-y;
    background-size: cover;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    font-size: 1vw;
    color: aliceblue;
}

.mainProfileForm {
    position: relative;
    display: flex;
    top: 19%;
    width: 60%;
    height: 70vh;
    margin: auto;
    background-color: rgba(31, 44, 100, 0.70);
    border-radius: 25px;
}

.profileNavbar {
    display: inline-block;
    width: 32%;
    height: 100%;
    background-color: #4A3E8B;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
}

.profileContent {
    width: 100%;
    padding-inline: 2em;
    align-items: center;
    justify-content: center;
    overflow: auto;
}

.profileUserInfo {
    display: flex;
    margin-left: 5em;
    margin-top: 1em;
    width: 50%;
}

.profileImgTop {
    display: inline-block;
    width: 3em;
    aspect-ratio: 1/1;
    border-radius: 50%;
}
.profileTopContainer {
    display: inline-block;
    margin-left: 1em;
    width: 100%;
}
.profileName {
    width: 100%;
    height: 50%;
    font-size: 1.2em;
}
.profileEmail {
    width: 100%;
    height: 50%;
}

.editForm {
    align-items: center;
    justify-content: center;
    width: 95%;
    right: 5%;
}

.editProfileButton {
    width: 10em;
    margin-top: 0.5em;
    margin-inline: auto;
    padding-block: 0.1em;
    font-size: 1.5em;
    text-align: center;
    color: aliceblue;
    border-radius: 1em;
    border: 1px solid #80C894;
    background: linear-gradient(180deg, rgba(38, 211, 55, 0.15) -3.62%, rgba(61, 158, 129, 0.20) 100%);
    backdrop-filter: blur(1em);
    user-select: none;
}
.editProfileButton:hover {
    cursor: pointer;
}

.InputFieldProfile {
    width: 70%;
    margin-inline: 15%;
    margin-left: calc(15% - 5px);
    margin-top: 10px;
    padding-inline: 5px;
    padding-block: 0em;
    font-size: 1.1em;
    height: 2em;
border-radius: 10px;
border: 1px solid rgba(255, 255, 255, 0.60);
background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -3.62%, rgba(255, 255, 255, 0.20) 100%);
backdrop-filter: blur(15px);
}

.profileNavbarLink {
    display: block;
    text-decoration: none;
    margin-inline: auto;
    margin-block: 0.3em;
    padding-bottom: 0.3em;
    padding-top: 0em;
    padding-inline: 0.3em;
    width: 85%;
    color: gray;
    font-size: 1em;
    border: 1px solid #cc75db00;
    &.active{
        /* background: linear-gradient(180deg, rgba(254, 200, 241, 0.20) 3.95%, rgba(254, 200, 241, 0.00) 14.47%), linear-gradient(298deg, #602EA6 26.83%, #C977D6 166.07%);
        */
        border: 1px solid #CC75DB;
        border-radius: 1em;
        color: aliceblue;
      }
}
.profileNavbarLink:hover {
    cursor: pointer;
}

ul {
    list-style: none; /* Remove default bullets */
  }
  
  ul li::marker {
    content: "• ";
    color: #CC75DB;
  }

.affiliateButton {
    width: 10em;
    margin-top: 0.5em;
    margin-inline: auto;
    padding-block: 0.1em;
    font-size: 1.5em;
    text-align: center;
    color: aliceblue;
    border-radius: 1em;
    background: linear-gradient(180deg, rgba(254, 200, 241, 0.90) -22.92%, rgba(254, 200, 241, 0.00) 26.73%), radial-gradient(137.13% 253.39% at 76.68% 66.67%, #8F5BD7 0%, #E8AAF2 100%);    ;
    backdrop-filter: blur(1em);
    &.active{
        background: var(--pink, linear-gradient(180deg, rgba(254, 200, 241, 0.90) -22.92%, rgba(254, 200, 241, 0.00) 26.73%), radial-gradient(137.13% 253.39% at 76.68% 66.67%, #602EA6 0%, #C977D6 100%));
    }
}
.affiliateButton:hover {
    cursor: pointer;
}
.linkHref {
    color: #7A59F2;
    text-decoration-line: underline;
}
.linkHref:hover {
    cursor: pointer;
}


.profileImageContainer {
    position: relative;
    display: inline-block;
}
.profileImageContainer:hover {
    cursor: pointer; 
}
.profileImginput {
    display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.profileImginput:hover {
    cursor: pointer;
}

.premiumLinkProfileButton {
    width: 70%;
    display: block;
    margin-top: 15px;
    margin-inline: 8%;
    padding-block: 0.1em;
    padding-inline: 1.5em;
    font-size: 1.5em;
    text-align: center;
    color: aliceblue;
    border-radius: 1em;
    background: linear-gradient(180deg, rgba(254, 200, 241, 0.90) -22.92%, rgba(254, 200, 241, 0.00) 26.73%), radial-gradient(137.13% 253.39% at 76.68% 66.67%, #8F5BD7 0%, #E8AAF2 100%);    ;
    backdrop-filter: blur(1em);
    &.active{
        background: var(--pink, linear-gradient(180deg, rgba(254, 200, 241, 0.90) -22.92%, rgba(254, 200, 241, 0.00) 26.73%), radial-gradient(137.13% 253.39% at 76.68% 66.67%, #602EA6 0%, #C977D6 100%));
    }
    text-decoration: none;
}

.premiumForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30em;
    border-radius: 1em;
    border: 1px solid rgba(255, 255, 255, 0.60);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) -3.62%, rgba(255, 255, 255, 0.20) 100%);
    margin-top: 1.5em;
}
.premiumForm li {
    font-size: 1.1em;
}
.premiumForm ul {
    padding-left: 0px;
}
.premiumTitle {
    font-size: 1.7em;
    margin-top: 0.7em;
}
.premiumButton {
    width: 80%;
    margin-top: 0.8em;
    margin-bottom: 0.5em;
    margin-inline: auto;
    padding-block: 0.1em;
    font-size: 1.5em;
    text-align: center;
    color: aliceblue;
    border-radius: 1em;
    border: 1px solid #80C894;
    background: linear-gradient(180deg, rgba(38, 211, 55, 0.15) -3.62%, rgba(61, 158, 129, 0.20) 100%);
    -webkit-backdrop-filter: blur(1em);
    backdrop-filter: blur(1em);
    -webkit-user-select: none;
    user-select: none;
}
.premiumButton:hover {
    cursor: pointer;
}

.paymentForm {
    display: flex;
    width: 80%;
    flex-direction: column;
}


@media screen and (max-width: 500px) {
    .mainProfileForm {
        width: 97%;
        background-size: 300% auto;
        
    }
    .mainProfileContainer {
        height: 130vh;
        font-size: 2vw;
    }
    .profileNavbar {
        width: 46%;
    }
    .InputFieldProfile {
        width: 90%;
        margin-inline: 5%;
        margin-left: calc(5% - 5px);
    }
    .premiumLinkProfileButton {
        width: 70%;
        display: block;
        margin-top: 15px;
        margin-inline: 5%;
        padding-block: 0.1em;
        padding-inline: 1.5em;
    }
}