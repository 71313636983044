.mainAuthContainer {
    margin-top: -82px;
    height: 120vh;
    background-image: url(./resources/FrameBg524.png);
    background-repeat: repeat-y;
    background-size: cover;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.SignupForm {
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 50%;
    right: 5%;
    top: 20%;
}

.authSwitchContainer {
    display: flex;
    margin: auto;
    margin-top: 20px;
    justify-content: space-between;
    width: 70%;
    height: 3vw;
    background-color: #AAA5E0;
    border-radius: 3vw;
}

.authSwitchItem {
    display: inline-block;
    height: 100%;
    width: 46%;
    text-align: center;
    font-size: 2vw;
    border-radius: 3vw;
    color: #060028;
}
.authSwitchItem:hover {
    cursor: pointer;
}

.InputFieldAuth {
    width: 70%;
    margin-inline: 15%;
    margin-left: calc(15% - 5px);
    margin-top: 15px;
    padding-inline: 5px;
    font-size: 1.6vw;
    height: 2vw;
    border-radius: 20px;
background-color: #AFAFFF;
}

.SubmitButton {
    width: 30%;
    font-size: 2.4vw;
    text-align: center;
    margin: auto;
    margin-top: 20px;
    padding-block: 5px;
    background-color: #1C1C5C;
    border-top: 2px solid #423C80;
    border-left: 2px solid #423C80;
    border-bottom: 2px solid #423C80;
    border-right: 2px solid #423C80;
    border-radius: 20px;
}
.SubmitButton:hover {
    cursor: pointer;
}

.errmsg {
    color: red;
    display: block;
    text-align: center;
    font-size: 1.5vw;
}
.success {
    color: greenyellow;
    display: block;
    text-align: center;
    font-size: 1.5vw;
}
.offscreen {
    display: none;
}

@media screen and (max-width: 500px) {
    .SignupForm {
        width: 80%;
        right: 8%;
        top: 34%;
    }
    .textProfileField {
        margin-left: 5% !important;
    }
    .leftMargin05 {
        margin-left: 0.5em !important;
    }
    .leftMargin1 {
        margin-left: 1em !important;
    }
    .profileFS7 {
        font-size: 7vw !important;
    }
    .profileFS46 {
        font-size: 4.6vw !important;
    }
    .InputFieldAuth {
        font-size: 3.2vw;
        height: 4vw;
    }
    .authSwitchContainer {
        width: 70%;
        height: 4.5vw;
    }
    .authSwitchItem {
        width: 46%;
        font-size: 3vw;
        border-radius: 3vw;
    }
}