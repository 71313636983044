.headerFont {
    color: #B59BFF;
    text-shadow: 0px 1px 10px #8d8cbb6d, 0px 1px 20px #ffffff65;
    font-family: Abstract;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.secondFont {
    color: #9687F3;
    font-family: Syne;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: lowercase;
}

.thirdFont {
    color: #2E2766;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.forthFont {
    color: #B59BFF;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /*text-transform: capitalize;*/
}

.fifthFont {
    color: #C1BEEE;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.sixthFont {
    color: #504394;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.seventhFont {
    color: #8880FE;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.blockStyle {
    display: block;
}

.inputField {
    width: 400px;
    height: 2.5rem;
    font-size: 2rem;
    color: aliceblue;

    border-radius: 25px;
    background: linear-gradient(292deg, #2B2562 46.11%, rgba(62, 39, 130, 0.96) 75.16%);
    box-shadow: 0px 0px 6px 2px #563C72;

    margin-bottom: 30px;
    margin-top: 15px;
    padding: 0 10px;
}
.inputField:-webkit-autofill,
.inputField:-webkit-autofill:hover,
.inputField:-webkit-autofill:focus,
.inputField:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px #2B2562 inset !important;
    box-shadow: 0 0 0px 1000px #2B2562 inset !important;
    -webkit-text-fill-color: aliceblue !important;
}

.buttonStyle {
    display: flex;
    height: 3rem;
    border-radius: 25px;
    border: 2px solid #563C72;
    background: #2E2766;
    box-shadow: 0px 4px 4px 0px #0f0f0f;
    backdrop-filter: blur(2px);
    color: #a489b8;
    font-family: Montserrat Alternates;
    text-shadow: 0px 4px 4px #0f0f0f;
    font-size: 2rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    padding: 1.8rem 5rem;
    user-select: none;
}
.buttonStyle:hover {
    border: 2px solid #563C72;
    background: linear-gradient(143deg, rgba(41, 15, 15, 0.29) 1.25%, rgba(97, 71, 140, 0.31) 79.53%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
}

@media screen and (max-width: 500px) {
    .inputField {
        max-width: 90%;
        margin-block: 5%;
        height: 1.5rem;
        font-size: 1.1rem;
        margin-bottom: 10px;
        margin-top: 5px;
    }
    .secondFont {
        font-size: 1rem;
        line-height: normal;
    }
    .buttonStyle {
        display: flex;
        width: 80%;
        height: 1rem;
        border-radius: 25px;
        border: 1px solid #563C72;
        box-shadow: 0px 2px 2px 0px #0f0f0f;
        backdrop-filter: blur(1px);
        color: #a489b8;
        font-family: Montserrat Alternates;
        text-shadow: 0px 4px 4px #0f0f0f;
        font-size: 1rem;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        margin-inline: 10%;
        padding: 1rem 1rem;
        user-select: none;
    }
}