#mainContainer {
  margin-left: 0;
  margin-top: 0;
  color: aliceblue;
  overflow: auto;
  width: 100%;
  /*overflow-x: hidden;*/
}

body {
  overflow: auto;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #3f3f3f;
}

body::-webkit-scrollbar-thumb {
  background: #888;
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#backgroundID {
  background-color: black;
  top: 0;
  margin-left: 0;
  width: 100%;
  z-index: -5;
}

.SubContainer {
  position: relative;
  display: grid;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 5px;
  height: 720px;
  background-image: url('./resources/6.png');
  background-size: 100%;
}

.NotFoundContainer {
  background-image: url('./resources/6.png');
  background-size: 200%;
  height: 100vh;
}

.SubContainer:last-of-type {
  margin-bottom: 0px;
}

.SubContainer * {
  grid-area: 1 / 1;
  margin: 0;
}

#topContainer {
  padding-top: 100px;
}

#matrixImage {
  position: relative;
  grid-area: 1 / 1;
  transform: translate(-100%, -100%);
  left: 95%;
  top: 100%;
  width: 620px;
  height: auto;
}

.bgImg {
  display: none;
  width: 100%;
  height: 100%;
}
.contentHeader {

  color: #B59BFF;
  text-shadow: 0px 2px 10px #8D8CBB, 0px 2px 250px #FFF;
  font-family: Abstract;
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contentText {
  color: rgba(255, 255, 255, 0.65);
  font-family: Stem;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 30px;
}

.contentButton {
  border-radius: 40px;
  border: 2px solid #563C72;
  background: #2E2766;
  box-shadow: 0px 4px 4px 0px #0f0f0f;
  backdrop-filter: blur(2px);
  color: #a489b8;
  font-family: Montserrat Alternates;
  text-shadow: 0px 4px 4px #0f0f0f;
  font-size: 26px;
  text-align: center;
  padding: 8px 70px;
  margin-top: 50px;
  width: fit-content;

}

.contentButton:hover {
  background: #20185f;
  cursor: pointer;
}
#taroImage {
  position: relative;
  grid-area: 1 / 1;
  transform: translate(-100%, -100%);
  right: -40%;
  top: 100%;
  width: 600px;
}

.contentLeft {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  width: 50%;
  max-width: 500px;
  margin-left: 10%;
  padding: 50px;
}
.contentRight {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  width: 50%;
  max-width: 500px;
  margin-left: 40%;
  padding: 50px;
}

#meditationsImage{
  position: relative;
  grid-area: 1 / 1;
  transform: translate(-100%, -100%);
  left: 96%;
  top: 100%;
  width: 600px;
}

@media screen and (max-width: 500px) {
  #matrixImage {
    max-width: 100vw;
    opacity: 0.5;
    z-index: 0;
    left: 100%;
  }
  #taroImage {
    max-width: 100vw;
    opacity: 0.5;
    z-index: 0;
    left: 100%;
    right: unset;
  }
  #meditationsImage {
    max-width: 100vw;
    opacity: 0.5;
    z-index: 0;
    left: 100%;
  }
  .SubContainer {
    width: unset;
    height: 400px;
    background-image: url('./resources/6.png');
    background-size: 400%;
  }
  .contentHeader {
    font-size: 1.4em;
  }
  .contentText {
    font-size: 1em;
  }
  .contentButton {
    font-size: 1em;
  }
  .contentLeft {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: unset;
    margin-left: unset;
    padding: unset;
    padding-inline: 10%;
    z-index: 1;
  }
  .contentRight {
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: unset;
    margin-left: unset;
    padding: unset;
    padding-inline: 10%;
    z-index: 1;
  }
}