#navbarLogo {
    margin: 0 0.5rem;
    height:95%;
    border-radius: 50%;
}

.signupNavLink {
    margin: 0;
    margin-right: 5px;
    width: fit-content;
}
.signupNavLink:hover {
    background-color: rgb(68, 117, 207);
}
.rightDivNavbar {
    width: fit-content;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
}

.rightContainer {
    display: flex;
    width: 100%;
    justify-content: right;
}

.ProfileTop {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inline {
    display: inline-block;
}

.clipToLeft {
    align-items:start;
    justify-content:left;
}

.inlineContainer {
    width:fit-content;
    display: flex;
    margin-left: 0;
    align-items: center;
    justify-content: center;
}

.form-container {
    display: flex;
    width: 100%;
    align-items: flex-start;
  }

  .buttons-container {
    display: inline-block;
    align-items: center;
    justify-content: space-between;
    width: 38px;
    max-width: 38px;
    height: 38px;
    margin: auto;
  }
  
  .buttons-container label {
    margin-right: 1rem;
  }

.formStyle {
    display: flex;
    width: 85%;
  align-items: center;
  padding: 3px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  }
  
  .labelStyle {
    font-size: 16px;
  margin-bottom: 8px;
  }

  #file-input {
    display: none;
  }

  #file-label {
    display: inline-block;
    padding: 10px 20px;
    background-color: #ccc;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }

  #file-label:active {
    background-color: #aaa;
  }
  
  .inputStyle, .inputStyle{
    padding: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  }

  textarea {
    display: inline-block;
    padding: 10px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    resize: none;
    height: 60px;
    transition: height 0.3s ease;
    overflow: auto;
  }
  
  textarea:focus {
    outline: none;
  }
  
  .buttonStyle {
    background-color: #007bff;
  color: white;
  border: none;
  height: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  }
  
  .buttonStyle:hover {
    background-color: #0056b3;
  }

  .form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
  }

.colorWhite {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    height: 50px;
    color: aliceblue;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
}

.colorWhite:hover {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    height: 50px;
    color: aliceblue;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    cursor: pointer;
}

#buttonContainerTop{
  position: fixed;
  top: 0;
  right: 0;
  height: 70px;
  padding-top: 20px;
  display: flex;
  width: 100%;
  z-index: 10;
  justify-content: center;
}

.buttonchik {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttonchik:hover {
  cursor: pointer;
}

.navbar-buttons {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
    top: 20px; 
    right: 3vw
}

.navlinksContainer{
  display: flex;
  margin-left: -10vw;
}

.linkButtonTop {
  font-size: 2vw;
  text-decoration: none;
  color: #8D8CBB;
  font-style: normal;
font-weight: 800;
line-height: normal;
  padding: 0.2vw 0.8vw;
  margin: 0 1vw;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  border: 2px solid #5c427a00;
  border-radius: 1.2vw;
  &.active{
    background-color: rgba(80, 67, 148, 0.10);
    border: 2px solid #5C427A;
  }
}

/*  CHAT  */

#dropdown-chat {
  display: none;
  position: absolute;
  background-color: #0e002d;
  font-size: 2vw;
  top: 3vw;
  right: 4vw;
  min-width: 40vw;
  max-width: 40vw;
  min-height: 500px;
  max-height: 500px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 1vw;
  z-index: 1;
}

.inputAreaChat {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  bottom: 0px;
  width: 100%;
  min-height: 1.5em;
  height: fit-content;
  border-radius: 1vw;
  background-color: #040075;
}

#chatInput {
  background-color: #0057b300;
  overflow-y: hidden;
  height: auto;
  resize: none;
  font-size: 0.5em;
  width: 70%;
  color: aliceblue;
}
.chatButton {
  width: 25%;
  display: inline-block;
  text-align: center;
  height: 100%;
  font-size: 0.6em;
  background-color: #5C427A;
  border-radius: 1vw;
  user-select: none;
}
.chatButton:hover {
  cursor: pointer;
}

/* END CHAT */

.languageBlock {
  display: flex; /*flex*/
  flex-direction: column;
  align-items: center;
  color: aliceblue;
  height: 0px; /*54px*/
  width: 85%;
  z-index: 0;
  background-color: #5C427A;
  padding-top: 0px;
  margin-top: calc(min(4vw, 48px)/-2 - 6px);
  border-bottom-left-radius: calc(min(4vw, 48px)/2);
  border-bottom-right-radius: calc(min(4vw, 48px)/2);
  transition: height 0.15s ease-in-out, padding-top 0.15s ease-in-out;
  overflow: hidden;
}
.activeLanguageSelect {
  height: 54px;
  padding-top: 25px;
}

@media screen and (max-width: 768px){
  .linkButtonTop {
    font-size: 2vw;
  }
  #buttonContainerTop {
    height: 30px;
  }
}
@media screen and (max-width: 500px) {

}