.mainMatrixContainer {
    padding-top: 150px;
    padding-left: 2%;
    margin-top: -60px;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    height: 3080px;
    background-image: url("./resources/6.png");
    background-size: 101% auto;
    background-repeat: repeat;
}

.mainMatrixHeader {
    display: block;
    font-size: 3.5vw;
    margin-left: min(20px, 2vw);
    margin-top: min(50px, 2vw);
    margin-bottom: min(40px, 2vw);
}

.matrixPurposeContainer {
    display: flex;
    align-items: start;
    margin-top: 20px;
    font-size: 2.5vw;
}

.matrixResultsContainer {
    margin-top: 10px;
    margin-bottom: 100px;
    width: 96%;
}

.matrixForUser {
    font-size: 2vw;
    margin-top: 40px;
}

.matrixResultsButton {
    display: flex;
    margin-top: min(10px, 1vw);
    padding-inline: 1vw;
    padding-top: 0.2vw;
    padding-bottom: 0.2vw;
    border-radius: 1rem;
    font-size: 2vw;
    text-align: center;
    align-items: center;
    background: rgba(68, 39, 245, 0.50);
    transition: border-radius 0.3s;
    user-select: none;
}
.matrixResultsButton:hover {
    cursor: pointer;
}

.matrixExpandableTab {
    width: calc(100% - 20px);
    height: fit-content;
    max-height: 0px;
    padding: 0px 0px;
    border-radius: 0 0 1rem 1rem;
    font-size: 1.5vw;
    color: aliceblue;
    background-color: #01003697;
    overflow: auto;
    transition: max-height 0.2s ease-in-out, padding 0.2s ease-in-out;
}

.scalesGrid {
    position: relative;
    display: grid;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.scalesImage{
    position: absolute;
    width: 40%;
    top: 40%;
}

.circle {
    color: aliceblue;
    font-size: 0.8vw;
    text-align: center;
    width: 1.2vw;
    height: 1.2vw;
    border: 3px solid #8880FE;
    border-radius: 50%;
    box-sizing: border-box;
  }

.bigFontSize {
    font-size: 2vw;
}

.mediumFontSize {
    font-size: 1.5vw;
}

.smallFontSize {
    font-size: 1.3vw;
}

.firstPage {
    position: relative;
}

.matrixImageFirstPage {
    width: 40%;
    position: absolute;
    right: -20%;
    top: 5%;
    animation: rotate 400s linear infinite;
}

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
}

.descriptionText {
    font-size: 1.2em;
}
.progress-container {
    width: 97%;
    background-color: #f3f3f3;
    border-radius: 5px;
    margin: 20px 0;
}
  
.progress-bar {
height: 30px;
background-color: #4caf50;
border-radius: 5px;
text-align: center;
line-height: 30px;
color: white;
font-weight: bold;
}

.TaroTextContainer {
    margin-top: -50%;
}

@media screen and (max-width: 500px) {
    .TaroTextContainer {
        margin-top: 0%;
    }
    .mainMatrixHeader {
        font-size: 5vw;
    }
    .yourMatrix {
        font-size: 3.6vw;
    }
    .mainMatrixContainer {
        padding-top: 130px;
        margin-top: -60px;
        width: 100%;
        box-sizing: border-box;
        position: fixed;
        height: 1580px;
        background-image: url("./resources/6.png");
        background-size: 300% auto;
        background-repeat: repeat;
    }
    .matrixResultsContainer {
        width: 96%;
        margin-inline: 2%;
    }
    .matrixImageFirstPage {
        top: 50%;
        left: -6%;
        width: 120%;
    }
    .descriptionText {
        font-size: 1.1em;
    }
    .matrixForUser {
        font-size: 1.3em;
    }
    .matrixExpandableTab {
        font-size: 1em;
    }
    .matrixResultsButton {
        font-size: 1.2em;
    }
}