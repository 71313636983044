.chakrasContainer {
    line-height: 1.6;
    margin: 0;
    padding: 20px;
    padding-top: 80px;
    background-image: url('./resources/6.png');
    background-size: 200%;
}
h1 {
    color: #B59BFF;
    text-shadow: 0px 2px 10px #8D8CBB, 0px 2px 250px #FFF;
    font-family: Abstract;
}
h2 {
    color: #b8a7e6;
}
p {
    color: rgba(255, 255, 255, 0.65);
    font-family: Stem;
}
.chakra {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.chakra h2 {
    margin-top: 0;
}