.profileImg {
    width: 2em;
    aspect-ratio: 1/1;
    border-radius: 50%;
    margin-inline: 0.3em;
}

.inlineContainer {
    width: 100%;
}

.senderName {
    font-size: 1em;
    color: aliceblue;
    width: calc(100% - 2em)
}

.messageText {

    padding-block: 3px;
    color: aliceblue;
    overflow: hidden;
}
.rightMessage {
    width: 60%;
    margin-left: 40%;
}
.leftMessage {
    width: 60%;
}

.messagesClass {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(500px - 2em);
}