.panel{
    display: grid;
    position: relative;
    color: rgb(241, 241, 241);
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;
}
.matrixImage{
    grid-area: 1 / 1;
    position: relative;
}
.BigNumber{
    grid-area: 1 / 1;
    text-align: center;
    position: relative;
    font-size: 2.5vw;
}
.MediumNumber{
    grid-area: 1 / 1;
    text-align: center;
    position: relative;
    font-size: 1.75vw;
}
.SmallNumber{
    grid-area: 1 / 1;
    text-align: center;
    position: relative;
    font-size: 1vw;
}
#id0 {
    left: -43.7%;
    top: 45.6%;
}
#id1 {
    left: 0%;
    top: 2%;
}
#id2 {
    left: 43.7%;
    top: 45.6%;
}
#id3 {
    left: 0%;
    top: 88.9%;
}
#id4 {
    left: 0%;
    top: 45.6%;
}
#id13 {
    left: -30.6%;
    top: 14.6%;
}
#id19 {
    left: 30.6%;
    top: 14.6%;
}
#id16 {
    left: 30.6%;
    top: 75.8%;
}
#id22 {
    left: -30.6%;
    top: 75.8%;
}
#id10 {
    left: -35.4%;
    top: 46.8%;
}
#id15 {
    left: -24.9%;
    top: 21.8%;
}
#id8 {
    left: 0;
    top: 11.3%;
}
#id21 {
    left: 24.9%;
    top: 21.8%;
}
#id12 {
    left: 35.4%;
    top: 46.8%;
}
#id18 {
    left: 25.1%;
    top: 71.7%;
}
#id6 {
    left: 0%;
    top: 82.1%;
}
#id24 {
    left: -25.1%;
    top: 71.7%;
}
#id9 {
    left: -30.6%;
    top: 48.2%;
}
#id14 {
    left: -21.7%;
    top: 26.5%;
}
#id7 {
    left: 0%;
    top: 17.5%;
}
#id20 {
    left: 21.7%;
    top: 26.5%;
}
#id11 {
    left: 30.6%;
    top: 48.2%;
}
#id17 {
    left: 21.7%;
    top: 69.8%;
}
#id5 {
    left: 0%;
    top: 78.8%;
}
#id23 {
    left: -21.7%;
    top: 69.8%;
}
#id26 {
    left: -15.3%;
    top: 48.2%;
}
#id25 {
    left: 0%;
    top: 32.8%;
}
#id28 {
    left: 8%;
    top: 70.9%;
}
#id27 {
    left: 15.3%;
    top: 63.4%;
}
#id29 {
    
    left: 22.35%;
    top: 56.5%;
}