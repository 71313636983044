.cookie-consent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .cookie-consent__content {
    background: #333;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 500px;
  }
  
  .cookie-consent__content h2 {
    margin-bottom: 20px;
  }
  
  .cookie-consent__content p {
    margin-bottom: 20px;
  }
  
  .cookie-consent__content a {
    color: #fff;
    text-decoration: underline;
  }
  
  .cookie-consent__content button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background: #555;
    color: #fff;
    border-radius: 5px;
  }
  
  .cookie-consent__content button:hover {
    background: #777;
  }